import React, { useCallback, useContext, useEffect, useMemo, useRef, useState, memo } from "react";
import { useTakerState } from "./TakerDocumentState";
import { TakerDocumentUpload } from "../../redux/models/dataModelTypes";
import { DocumentKeyTermsHolder } from "../../types/documentKeyTerms";
import {
    useGetLatestTakerDocumentUploadDataQuery,
    useUpdateTakerDocumentUploadDataMutation,
    useDeleteTakerDocumentUploadDataMutation
} from "../../redux/services/takerData";
import { DocumentAnnotation, DocumentHighlight, KeyTerm, LexicalDocument } from "../../types/taker/documentkeyterms.generated";
import { dummyKeyTermData } from "./dummyKeyTermData";

export interface KeyTermSummaryUpdate {
    takerDocumentUploadId: string;
    termName: string;
    summary: string;
};

export interface KeyTermSummaryAIRequest {
    keyTermId: string;
    isFulfilled: boolean;
}

interface DocumentKeyTermsService {
    // Key terms basic modifiers
    createNewKeyTerm: (tn: string) => void;
    createKeyTerms: (kts: KeyTerm[]) => void;
    removeKeyTerm: (tn: string) => void;
    removeAllKeyTerms: () => void;
    updateKeyTermInfo: (otn: string, tn: string, c: string[]) => void;
    updateKeyTermInfoAndSummary: (otn: string, tn: string, c: string[], s: string) => void;
    flushKeyTermInfoAndSummary: (otn: string, tn: string, c: string[], s: string) => void;
    updateKeyTermSummary: (tn: string, s: string) => void;
    updateKeyTermSummaries: (ktsus: KeyTermSummaryUpdate[]) => void;
    shiftKeyTermUp: (tn: string) => void;
    shiftKeyTermDown: (tn: string) => void;
    toggleKeyTermLocked: (tn: string) => void;
    addDocumentAnnotation: (tns: string[], da: DocumentAnnotation) => void;
    removeDocumentAnnotation: (tn: string, da: DocumentAnnotation) => void;
    containsKeyTerm: (tn: string) => boolean;
    bulkUpdateDocumentAnnotations: (tnls: string[][], ogTnls: string[][], das: DocumentAnnotation[]) => void;

    // Lexical document modifiers
    createLexicalDocument: (ld: LexicalDocument) => void;
    updateLexicalDocumentPage: (di: string, p: number, l: Object) => void;
    removeLexicalDocument: (di: string) => void;

    // Fetch transformed data
    getTakerDocumentName: (documentId: string) => string;
    getAnnotations: (identifier: string, page: number) => DocumentAnnotation[] | undefined;
    getTextForAnnotation: (annotationId: string) => string;
    getTextContentForDocumentHighlights: (id: string, pn: number, dh: DocumentHighlight[]) => string;
    getMockKeyTermSummary: (tn: string) => string;

    setOpenDetailsModal(id: number | undefined): void;

    addOrUpdateLatestAIRequest(request: KeyTermSummaryAIRequest): void;

    // Raw data
    keyTerms?: KeyTerm[];
    lexicalDocuments?: LexicalDocument[];
    currentlyOpenDetailsModal: number | undefined;
}

interface KeyTermGroupStateHookData {
    initStateCount: number;
    isKeyTermsUninitialized: boolean;
    lastSavedTimestamp: number | undefined;
    isKeyTermsDataLoading: boolean;
    currentlyOpenDetailsModal: number | undefined;
    isKeyTermGroupStateDirty: boolean;
    takerDocumentUpload?: TakerDocumentUpload;
    deleteTakerDocumentUploadData: () => void;
    documentKeyTermsService: DocumentKeyTermsService;
    aiSummaryRequests: Record<string, KeyTermSummaryAIRequest>;
}

const Context = React.createContext({});

const SAVE_DELAY = 500;

export function useKeyTermGroupState(): KeyTermGroupStateHookData {
    return useContext(Context) as KeyTermGroupStateHookData;
}

interface KeyTermGroupStateInnerProps {
    takerDocumentUploadId: string;
    children: any;
}

const KeyTermGroupStateInner = ({
    takerDocumentUploadId,
    children
}: KeyTermGroupStateInnerProps) => {
    const {
        taker,
        takerDocumentId,
        takerPermissionState,
        takerDocumentUploads,
        isTakerDocumentUninitialized
    } = useTakerState();

    const [
        updateTakerDocumentUploadData,
        updateTakerDocumentUploadDataRes
    ] = useUpdateTakerDocumentUploadDataMutation();
    const [
        deleteTakerDocumentUploadData,
    ] = useDeleteTakerDocumentUploadDataMutation();

    const {
        data: latestKeyTermsData,
        isFetching: isKeyTermsDataLoading,
        isUninitialized: isKeyTermsDataUninitialized,
        isError: isKeyTermsDataError
    } = useGetLatestTakerDocumentUploadDataQuery({ 
        takerDocumentUploadId: takerDocumentUploadId, 
        contentType: "KEY_TERMS" 
    });

    const [updatingJob, setUpdatingJob] = useState<any>(null);
    const [initStateCount, setInitStateCount] = useState<number>(0);
    const [isStateDirty, setIsStateDirty] = useState<boolean>(false);
    const [lastMarkedClean, setLastMarkedClean] = useState<number>();
    const [flushIndex, setFlushIndex] = useState<number>(0);
    const [openDetailsModal, setOpenDetailsModal] = useState<number | undefined>(undefined);
    const [openRequests, setOpenRequests] = useState<Record<string, KeyTermSummaryAIRequest>>({});

    useEffect(() => {
        if (isStateDirty && updateTakerDocumentUploadDataRes.isSuccess) {
            setIsStateDirty(false);
            setLastMarkedClean(updateTakerDocumentUploadDataRes.data.updatedAt);
        }
    }, [updateTakerDocumentUploadDataRes]);

    const targetTakerDocumentUpload = useMemo(() => {
        if (takerDocumentUploads) {
            for (const takerDocumentUpload of takerDocumentUploads) {
                if (takerDocumentUpload.id === takerDocumentUploadId) {
                    return takerDocumentUpload;
                }
            }
        }
    }, [takerDocumentUploads, takerDocumentUploadId]);

    const documentIdToNameForCurrentGroup = useMemo(() => {
        let documentIdToNameMap: Record<string, string> = {};
        if (targetTakerDocumentUpload?.fileUpload?.fileUploadItems) {
            for (let i = 0; i < targetTakerDocumentUpload?.fileUpload?.fileUploadItems?.length; i++) {
                documentIdToNameMap[targetTakerDocumentUpload?.fileUpload?.fileUploadItems[i].id] = targetTakerDocumentUpload?.fileUpload?.fileUploadItems[i].label;
            }
        }
        return documentIdToNameMap
    }, [targetTakerDocumentUpload?.fileUpload?.fileUploadItems?.length]);

    const activeTargetDocumentKeyTermsState = useRef<DocumentKeyTermsHolder | undefined>(
        latestKeyTermsData && new DocumentKeyTermsHolder(latestKeyTermsData.content)
    );

    const doDeleteTakerDocumentUploadDataCallback = useCallback(() => {
        if (taker && latestKeyTermsData) {
            deleteTakerDocumentUploadData({
                id: latestKeyTermsData.id,
                takerId: taker.id,
                takerDocumentId: takerDocumentId
            });
        }
    }, [deleteTakerDocumentUploadData, latestKeyTermsData]);

    useEffect(() => {
        if (!!latestKeyTermsData) {
            activeTargetDocumentKeyTermsState.current = new DocumentKeyTermsHolder(
                latestKeyTermsData.content
            );
            setInitStateCount(initStateCount + 1);
        }
    }, [latestKeyTermsData?.id]);

    useEffect(() => {
        if(!!flushIndex && flushIndex != 0 && flushIndex === activeTargetDocumentKeyTermsState.current?.documentKeyTerms.keyTerms.length) {
            setFlushIndex(0);
            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
            doStateUpdate.current(newState);
            console.log("ready to save!");
        }
    }, [flushIndex]);

    const doStateUpdateFunc = (newState: DocumentKeyTermsHolder) => {
        // no updates for non read/roles
        if (!takerPermissionState.isReadWrite) {
            return
        }
        
        activeTargetDocumentKeyTermsState.current = newState;
        setIsStateDirty(true);

        if (updatingJob) {
            clearTimeout(updatingJob)
        }
        let newUpdatingJob = setTimeout(() => {
            if (taker &&
                latestKeyTermsData &&
                activeTargetDocumentKeyTermsState.current) {
                updateTakerDocumentUploadData({
                    takerId: taker.id,
                    takerDocumentId,
                    id: latestKeyTermsData.id,
                    content: activeTargetDocumentKeyTermsState.current.documentKeyTerms
                });
            }
        }, SAVE_DELAY);
        setUpdatingJob(newUpdatingJob);

    };

    const doStateUpdate = useRef(doStateUpdateFunc);
    useEffect(() => {
        doStateUpdate.current = doStateUpdateFunc;
    });

    const indexedDocumentAnnotations = useMemo(() => {
        if (activeTargetDocumentKeyTermsState.current) {
            return activeTargetDocumentKeyTermsState.current.buildIndexedDocumentAnnotations();
        };
    }, [activeTargetDocumentKeyTermsState.current?.documentKeyTerms]);

    const annotationIdToHighlightedText = useMemo(() => {
        const parser = new DOMParser();
        let annotationIdToHighlightedText: Record<string, string> = {};
        if (indexedDocumentAnnotations && activeTargetDocumentKeyTermsState?.current?.documentKeyTerms?.lexicalDocuments) {
            for (const identifier in indexedDocumentAnnotations) {
                let currentLexicalDocument = activeTargetDocumentKeyTermsState.current.documentKeyTerms.lexicalDocuments.find(ld => ld.identifier === identifier);
                for (const page in indexedDocumentAnnotations[identifier]) {
                    const pageHtml = (currentLexicalDocument?.lexicalPages[page] as any).root?.children[0].rawHtml;
                    const pageDocument = parser.parseFromString(pageHtml, "text/html");
                    for (const annotation of indexedDocumentAnnotations[identifier][page]) {
                        let entryString = "";
                        for (let documentHighlight of annotation.documentHighlights) {
                            entryString += pageDocument.getElementById(documentHighlight.elementId)?.innerText;
                        }
                        annotationIdToHighlightedText[annotation.annotationId] = entryString;
                    }
                }
            }
        };
        return annotationIdToHighlightedText;
    }, [indexedDocumentAnnotations]);

    const lastSavedTimestamp = useMemo(
        () => !isKeyTermsDataError && (lastMarkedClean || latestKeyTermsData?.updatedAt),
        [
            isKeyTermsDataError,
            lastMarkedClean, 
            latestKeyTermsData
        ]
    );

    const getTextContentForDocumentHighlights = useCallback((lexicalDocumentIdentifier: string, pageIndex: number, documentHighlights: DocumentHighlight[]) => {
        if (activeTargetDocumentKeyTermsState?.current?.documentKeyTerms?.lexicalDocuments) {
            const currentLexicalDocument = activeTargetDocumentKeyTermsState.current.documentKeyTerms.lexicalDocuments.find(ld => ld.identifier === lexicalDocumentIdentifier);
            if (!currentLexicalDocument) {
                return;
            }

            const page = currentLexicalDocument.lexicalPages[pageIndex] as any;
            const pageDocument = (new DOMParser()).parseFromString(page['root']['children'][0]['rawHtml'] , "text/html");
            
            let entryString = "";
            for (const docHighlight of documentHighlights) {
                entryString += pageDocument.getElementById(docHighlight.elementId)?.innerText;                
            }
            return entryString;
        };
    }, [activeTargetDocumentKeyTermsState]);

    return (
        <Context.Provider
            value={{
                initStateCount: initStateCount,
                isKeyTermsUninitialized: isTakerDocumentUninitialized || isKeyTermsDataUninitialized,
                currentlyOpenDetailsModal: openDetailsModal,
                lastSavedTimestamp,
                isKeyTermGroupStateDirty: isStateDirty,
                isKeyTermsDataLoading,
                takerDocumentUpload: targetTakerDocumentUpload,
                aiSummaryRequests: openRequests,
                deleteTakerDocumentUploadData: () => doDeleteTakerDocumentUploadDataCallback(),
                documentKeyTermsService: {
                    createNewKeyTerm: (tn) => {
                        if (activeTargetDocumentKeyTermsState.current && !activeTargetDocumentKeyTermsState.current.containsKeyTerm(tn)) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.createNewKeyTerm(tn);
                            doStateUpdate.current(newState);
                        }
                    },
                    createKeyTerms: (kts) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            for (const kt of kts) {
                                newState.createKeyTerm(kt);
                            }
                            doStateUpdate.current(newState);
                        }
                    },
                    removeKeyTerm: (tn) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.removeKeyTerm(tn);
                            doStateUpdate.current(newState);
                        }
                    },
                    removeAllKeyTerms: () => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.removeAllKeyTerms();
                            doStateUpdate.current(newState);
                        }
                    },
                    updateKeyTermInfo: (otn, tn, c) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.updateKeyTermInfo(otn, tn, c);
                            doStateUpdate.current(newState);
                        }
                    },
                    updateKeyTermInfoAndSummary: (otn, tn, c, s) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.updateKeyTermInfoAndSummary(otn, tn, c, s);
                            doStateUpdate.current(newState);
                        }
                    },
                    flushKeyTermInfoAndSummary: (otn, tn, c, s) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            activeTargetDocumentKeyTermsState.current.updateKeyTermInfoAndSummary(otn, tn, c, s);
                            setFlushIndex(prevCount => prevCount + 1);
                        }                        
                    },
                    updateKeyTermSummary: (tn, s) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.updateKeyTermSummary(tn, s);
                            doStateUpdate.current(newState);
                        }
                    },
                    updateKeyTermSummaries: (ktsus: KeyTermSummaryUpdate[]) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            for (const ktsu of ktsus) {
                                newState.updateKeyTermSummary(ktsu.termName, ktsu.summary);
                            }
                            doStateUpdate.current(newState);
                        }
                    },
                    shiftKeyTermUp: (tn) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.updateKeyTermPosition(tn, -1);
                            doStateUpdate.current(newState);
                        }
                    },
                    shiftKeyTermDown: (tn) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.updateKeyTermPosition(tn, 1);
                            doStateUpdate.current(newState);
                        }
                    },
                    toggleKeyTermLocked: (tn) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.toggleKeyTermLocked(tn);
                            doStateUpdate.current(newState);
                        }
                    },
                    addDocumentAnnotation: (tns, da) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.addDocumentAnnotation(tns, da);
                            doStateUpdate.current(newState);
                        }
                    },
                    removeDocumentAnnotation: (tn, da) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.removeDocumentAnnotation(tn, da);
                            doStateUpdate.current(newState);
                        }
                    },
                    containsKeyTerm: (tn: string) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            return activeTargetDocumentKeyTermsState.current.containsKeyTerm(tn);
                        }
                        return false;
                    },
                    bulkUpdateDocumentAnnotations(tnls, ogTnls, das) {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            for (let i = 0; i < tnls.length; i++) {
                                let termNames = tnls[i];
                                let ogTermNames = ogTnls[i];

                                // remove terms that no longer appear in the list, original - new
                                let removed = [...ogTermNames].filter(tn => !termNames.includes(tn));
                                for (const tn of removed) {
                                    newState.removeDocumentAnnotation(tn, das[i]);
                                }

                                // find new terms appear in the list, new - original
                                let added = [...termNames].filter(tn => !ogTermNames.includes(tn));
                                newState.addDocumentAnnotation(added, das[i]);
                            }
                            doStateUpdate.current(newState);
                        }
                    },
                    createLexicalDocument: (ld: LexicalDocument) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.createLexicalDocument(ld);
                            doStateUpdate.current(newState);
                        }
                    },
                    updateLexicalDocumentPage: (di, p, l) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.updateLexicalDocumentPage(di, p, l);
                            doStateUpdate.current(newState);
                        }
                    },
                    removeLexicalDocument: (di) => {
                        if (activeTargetDocumentKeyTermsState.current) {
                            const newState = new DocumentKeyTermsHolder(activeTargetDocumentKeyTermsState.current.documentKeyTerms);
                            newState.removeLexicalDocument(di);
                            doStateUpdate.current(newState);
                        }
                    },
                    getTakerDocumentName(documentId: string) {
                        return documentIdToNameForCurrentGroup[documentId];
                    },
                    getMockKeyTermSummary: (tn: string) => {
                        for (const kt of dummyKeyTermData.keyTerms) {
                            if (kt.termName === tn) {
                                return kt.summary;
                            }
                        }
                    },
                    getAnnotations: (identifier, page) => {
                        return indexedDocumentAnnotations &&
                            indexedDocumentAnnotations[identifier] &&
                            indexedDocumentAnnotations[identifier][page];
                    },
                    getTextContentForDocumentHighlights,
                    getTextForAnnotation: (annotationId: string) => {
                        return annotationIdToHighlightedText[annotationId];
                    },
                    setOpenDetailsModal(id: number | undefined) {
                        setOpenDetailsModal(id);
                    },
                    addOrUpdateLatestAIRequest(request: KeyTermSummaryAIRequest) {
                       let openRequestsCopy = { ...openRequests };
                        openRequestsCopy[request.keyTermId] = request;
                        setOpenRequests(openRequestsCopy);
                    },
                    keyTerms: activeTargetDocumentKeyTermsState.current &&
                        activeTargetDocumentKeyTermsState.current.documentKeyTerms.keyTerms,
                    lexicalDocuments: activeTargetDocumentKeyTermsState.current &&
                        activeTargetDocumentKeyTermsState.current.documentKeyTerms.lexicalDocuments
                } as DocumentKeyTermsService
            }}
        >
            {children}
        </Context.Provider>
    );
};

interface KeyTermGroupStateProps {
    takerDocumentUploadId: string | null;
    children: any;
}

const KeyTermGroupState = ({
    takerDocumentUploadId,
    children
}: KeyTermGroupStateProps) => {
    if (!takerDocumentUploadId) {
        return (
            <>
                {children}
            </>
        );
    }
    return (
        <KeyTermGroupStateInner 
            takerDocumentUploadId={takerDocumentUploadId} 
            children={children}
        />
    );
}

export default memo(KeyTermGroupState);
